import * as Sentry from '@sentry/browser';


Sentry.init({
    dsn: window.SysFrontendSentry,
    release: window.SysFrontendSentryRelease,
    ignoreErrors: [
        'ResizeObserver loop limit exceeded', // ignore Chrome video internal error: https://crbug.com/809574
        'UnhandledRejection', // uninformative messages after errors without reject handler
    ],
});

if (window.REQUEST_USERNAME && window.REQUEST_EMAIL) {
    Sentry.setUser({
        email: window.REQUEST_EMAIL,
        username: window.REQUEST_USERNAME,
    });
}

window.Sentry = Sentry;
